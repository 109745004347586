import * as React from 'react'
import PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import slugify from "@sindresorhus/slugify"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination} from 'swiper';

// Components
import Layout from '../../components/layout/Layout'
import ProduitCard from '../../components/card/Produit'
import ProduitMarqueCategorieCard from '../../components/card/ProduitMarqueCategorie'

SwiperCore.use([Pagination]);

// Marque
const Marque = ({ pageContext, data }) => {
  return (
    <Layout pageTitle={data.marque.frontmatter.nom} pageDescription={data.marque.frontmatter.body} headerClassPosition="sticky-top">
      <section key="breadcrumbs-section" className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {
              data.marque.frontmatter.nom
              ? <h2>{data.marque.frontmatter.nom}</h2>
              : null
            }
            <ol>
              <li>
                <Link to={`/`} className="text-reset">Accueil</Link>
              </li>
              <li>
                <Link to={`/marques`} className="text-reset">Marques</Link>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section key="details-section" className="brand-details">
        <div className="container">
          <div className="row gy-4">
            {data.marque.frontmatter.images ?
              <div key="images" className="col-lg-8">
                <Swiper
                  modules={[Navigation, Pagination]}
                  navigation
                  pagination={{"clickable": true}}
                  className="brand-details-slider swiper"
                >
                  {
                    data.marque.frontmatter.images.map((image,index) => (
                      <SwiperSlide key={index} className="swiper-slide">
                        <GatsbyImage
                          image={getImage(image)}
                          className="swiper-slide"
                          alt={data.marque.frontmatter.nom}
                        />
                      </SwiperSlide>                  
                    ))
                  }
                </Swiper>
              </div>
            : null}
            <div key="text" className="col-lg-4">
              <div key="info" className="brand-info">
                <div className="mb-4 border-bottom">
                  <GatsbyImage
                    image={getImage(data.marque.frontmatter.hero_image_16x9)}
                    className="card-img-top"
                    alt={data.marque.frontmatter.hero_image_alt}
                  />
                </div>
                <ul>
                  {
                    data.marqueProduitsCategories.group.length
                    ? <li key="categories"><strong className="me-1">Catégories:</strong>
                        <ul className="list-unstyled">
                          {
                            data.marqueProduitsCategories.group.map((categorie, index) => (
                              <li key={index} className="ms-2 mt-1">
                                <Link to={`/produits/marques/${slugify(data.marque.frontmatter.nom)}/categories/${slugify(categorie.nom)}`} className="text-reset">{categorie.nom}</Link>
                              </li>
                            ))
                          }
                        </ul>
                      </li>
                    : null
                  }
                  {
                    data.marque.frontmatter.pays
                    ? <li key="pays"><strong className="me-1">Pays:</strong>{data.marque.frontmatter.pays}</li>
                    : null
                  }
                  {
                    data.marque.frontmatter.site_internet
                    ? <li key="site_internet" className="text-center"><a href={data.marque.frontmatter.site_internet} className="btn btn-sm btn-primary text-white" target="_blank" rel="noreferrer">Site internet</a></li>
                    : null
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section key="description-section" className="brand-details">
          <div className="container">
            <div key="description" className="brand-description">
                <h2>Informations générales</h2>
                <div className="text-justify">
                  <MDXRenderer>
                    {data.marque.body}
                  </MDXRenderer>
                </div>
              </div>
          </div>
      </section>
      {data.marqueProduitsCategories.group.length ? 
        <section key="marque-produits-categories-section" className="products">
          <div className="container">
            <div key="marque-produits-categories-title" className="section-title">
              <h2>Catégories de produits de {data.marque.frontmatter.nom}</h2>
            </div>
            <div key="marque-produits-categories" className="row">
                {
                  data.marqueProduitsCategories.group.map((categorie, index) => (
                    <ProduitMarqueCategorieCard key={index} data={categorie} marque={data.marque}/>
                  ))
                }
            </div>
          </div>
        </section>
      : null }
      {data.marque.frontmatter.videos ? 
        <section key="videos-section" className="videos">
          <div className="container">
            <div key="videos-title" className="section-title">
              <h2>Vidéos</h2>
            </div>
            <div key="videos" className="row">
                {
                  data.marque.frontmatter.videos.map((video, index) => (
                    <div key={index} className="col-lg-4">
                      <div className="ratio ratio-16x9 mb-3">
                        <iframe src={video} title={data.marque.frontmatter.nom} allowFullScreen></iframe>
                      </div>
                    </div>
                  ))
                }
            </div>
          </div>
        </section>
      : null }
      {data.marqueProduits.nodes.length ? 
        <section key="marque-produits-section" className="products">
          <div className="container">
            <div key="marque-produits-title" className="section-title">
              <h2>Produits de {data.marque.frontmatter.nom}</h2>
            </div>
            <div key="marque-produits" className="row">
                {
                  data.marqueProduits.nodes.map((produit, index) => (
                    <ProduitCard key={index} data={produit}/>
                  ))
                }
            </div>
          </div>
        </section>
      : null }
    </Layout>
  )
}

Marque.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    marque: PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          nom: PropTypes.string.isRequired,
        }),
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
    }).isRequired
  }),
}

export default Marque

export const query = graphql`
  query ($id: String, $marque: String) {
    marque: mdx(id: {eq: $id}, fileAbsolutePath: {regex: "/marques/"}) {
      frontmatter {
        nom
        marque
        pays
        site_internet
        hero_image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_16x9 {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        images {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        videos
        actif
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
    marqueProduits: allMdx(
      filter: {fileAbsolutePath: {regex: "/produits/"}, frontmatter: {marque: {eq: $marque}}}
      sort: {fields: frontmatter___date, order: ASC}
      limit: 6
    ) {
      nodes {
        frontmatter {
          titre
          marque
          categorie
          sous_categorie
          card_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          card_image_alt
          hero_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          hero_image_alt
          hero_image_credit_link
          hero_image_credit_text
          accueil
          actif
          date(formatString: "MMMM D, YYYY")
        }
        id
        slug
      }
      totalCount
    }
    marqueProduitsCategories: allMdx(
      filter: {fileAbsolutePath: {regex: "/produits/"}, frontmatter: {marque: {eq: $marque}}}
    ) {
      group(field: frontmatter___categorie) {
        nom: fieldValue
        totalCount
        produit: group(field: frontmatter___title, limit: 1) {
          edges {
            node {
              frontmatter {
                title
                marque
                categorie
                sous_categorie
                hero_image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`